.profile-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .headerSpace.container {
    min-height: 90px !important;
  }
}

.settings-tabs {
  height: 43px;
  display: flex;
  outline: none !important;
  box-shadow: none;
}

.settings-tab-name {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-left: 0.5rem;
}

.list-group-item:first-child {
  border: none;
}

.list-group-item + .list-group-item {
  border: none;
}

.list-group-item:last-child {
  border: none;
}

.list-group-item-light.list-group-item-action.active {
  color: #8c8c8d !important;
  background-color: #ececf6 !important;
  border-color: #ececf6 !important;
}

.billing-details .plan,
.billing-details .next-billing,
.billing-details .price {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.billing-details .plan .label,
.billing-details .next-billing .label,
.billing-details .price .label {
  font-size: 15px;
  line-height: 22px;
  margin: 0 4px 0 0;
}

.billing-details .plan .value,
.billing-details .next-billing .value,
.billing-details .price .value {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}
