.error-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .bg-errors {
    padding: 150px 0;
    height: auto;
  }
}

.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.error-page {
  font-size: 20px;
  letter-spacing: 1px;
}
