//
// features.scss
//
.feature-list {
  li {
    margin-bottom: 5px;
    font-size: 16px;
  }
}
.features {
  .icon {
    font-size: 24px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: lighten($primary, 45%);
    text-align: center;
  }
  .image {
    &:before {
      content: "";
      position: absolute;
      bottom: 5px;
      right: -15px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      transform: rotate(33.75deg);
      background: linear-gradient(45deg, transparent, rgba($primary, 0.09));
      z-index: -1;
    }
  }
  .ride-image {
    transform: rotate(-45deg);
    img {
      border-radius: 100% 100% 100% 0;
    }
  }
  .title-2 {
    font-size: 20px;
  }
  &:hover {
    .image {
      &:before {
        background: rgba($primary, 0.07);
        animation: spinner-border 5s linear infinite;
      }
    }
  }
}
.key-feature {
  transition: all 0.3s ease;
  .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: linear-gradient(45deg, transparent, rgba($primary, 0.15));
    font-size: 24px;
  }
  &:hover {
    transform: scale(1.05);
  }
}
.key-feature .content,
.features {
  .title {
    font-size: 18px;
  }
}

// Work process
.work-process {
  transition: all 0.5s ease;
  .title {
    font-size: 20px;
  }
  .step,
  .step-icon {
    font-size: 45px;
    opacity: 0.06;
  }
  a {
    font-size: 15px;
  }
  &:hover {
    transform: translateY(-10px);
  }
}

//course.html
.course-feature {
  .title {
    font-size: 20px;
    &:hover {
      color: $primary !important;
    }
  }
  .full-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto;
    z-index: -2;
    transition: all 0.5s ease;
  }
  &:hover {
    transform: translateY(-10px);
    .full-img {
      opacity: 0.08;
    }
  }
}

//index-crypto.html and index-course.html
.crypto-table,
.course-feature {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
  margin: -260px 0 0px;
  box-shadow: $shadow;
}

//index-job.html
.catagories {
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-10px);
    box-shadow: $shadow;
  }
  a {
    &:hover {
      color: $primary !important;
    }
  }
}

//Classic App
.app-feature-shape-right,
.app-feature-shape-left {
  &:after {
    bottom: 0rem;
    width: 30rem;
    height: 40rem;
    border-radius: 10rem;
    opacity: 0.3;
  }
}

.app-feature-shape-right {
  &:after {
    left: 10rem;
    transform: rotate(150deg);
  }
}

.app-feature-shape-left {
  &:after {
    right: 10rem;
    transform: rotate(210deg);
  }
}
.app-subscribe {
  position: absolute;
  top: -200px;
}

//Classic Saas
.saas-feature-shape-left,
.saas-feature-shape-right {
  &:after {
    bottom: -3rem;
    width: 35rem;
    height: 35rem;
    opacity: 0.4;
    border-radius: 15rem;
    transform: rotate(145deg);
  }
}
.saas-feature-shape-left {
  &:after {
    left: -10rem;
  }
}

.saas-feature-shape-right {
  &:after {
    right: -10rem;
  }
}
@media (max-width: 768px) {
  .process-arrow:after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .course-feature,
  .crypto-table {
    margin: 0 0 30px;
  }
  .app-subscribe {
    position: relative;
    top: 0;
  }
}
