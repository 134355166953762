.dashboard-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 120px 0 120px;
}

@media (max-width: 767px) {
  .bg-profile {
    height: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.indiehackers-icon {
  width: 14px !important;
  height: 14px !important;
}

.react-calendar-heatmap .color-github-0 {
  fill: #f3f3f3;
}

.react-calendar-heatmap .color-github-1 {
  fill: #ff7f94;
}

.btn.btn-danger.user-btn {
  font-weight: 600;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em;
  transition: 0.3s linear all;
}
.btn.btn-danger.user-btn:hover {
  background: #e81035;
  border-color: #e81035;
}

.btn.btn-danger.new-company {
  font-weight: 600;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em;
  transition: 0.3s linear all;
}
.btn.btn-danger.new-company:hover {
  background: #e81035;
  border-color: #e81035;
}

.notification-header-icon {
  margin-bottom: 7px;
}

.notification-item {
  padding: 16px 6px;
}

.notification-item:hover {
  background-color: #f1f3f4;
}
.more-option {
  display: none !important;
  background-color: #f1f3f4 !important;
  border-color: #f1f3f4 !important;
  cursor: pointer;
}
.more-option:hover {
  background-color: #f1f3f4 !important;
}

.notification-item:hover .more-option {
  display: inline-block !important;
}
