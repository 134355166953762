.login-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

.a-content {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.a-signin-header {
  margin-top: 40px;
  margin-bottom: 24px;
  display: inline-block;
}

.a-signin-label {
  font-size: 26px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  color: #3b4f60;
  margin-bottom: 16px;
}

.a-text-box {
  position: relative;
  margin-bottom: 12px;
}

.a-text-box-content {
  font-weight: 400;
  color: #0d202f;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  line-height: 1a-5;
  height: 54px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #e6e7e7;
  border-radius: 8px;
  outline: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.a-text-box-content:hover {
  border: 1px solid #cad0d4;
}

.a-text-box-content:focus {
  border: 1px solid #3b4f60;
}

.a-text-box-content::placeholder {
  color: #6b7a86;
}

.a-btn {
  font-weight: 500;
  border-radius: 8px;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
  padding: 16px 32px;
  min-width: 120px;
  background-color: #5850ec;
  color: #fff;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}

.a-btn-disabled {
  font-weight: 500;
  border-radius: 8px;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  border: 2px solid transparent;
  position: relative;
  padding: 16px 32px;
  min-width: 120px;
  background-color: #6387ff;
  color: #fff;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}

.a-btn:hover {
  background-color: #4065e3;
}

.a-btn:focus {
  outline: none;
  background-color: #4065e3;
}

.a-btn:visited {
  background-color: #4065e3;
}

.a-btn:active {
  background-color: #4065e3;
}

.a-error {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #f43543;
  text-align: start !important;
  margin-bottom: 0;
  margin-top: 2px;
  padding-left: 1px;
}

.a-cursor-pointer {
  cursor: pointer;
}
