//
// helper.scss
//
.section {
  padding: 100px 0;
  position: relative;
}
.section-two {
  padding: 60px 0;
  position: relative;
}
.bg-overlay {
  background-color: $overlay;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.bg-overlay-gradient {
  background-color: $gradident-overlay;
}

.bg-overlay-white {
  background-color: $bg-overlay-white;
}

.bg-overlay-gradient2 {
  background-image: linear-gradient(90deg, $success, $primary, $success);
  opacity: 0.5;
}
//Title
.title-heading {
  line-height: 26px;
  .heading {
    font-size: 45px;
    letter-spacing: 1px;
  }
  .para-desc {
    font-size: 18px;
  }
}
.section-title {
  position: relative;
  .title {
    letter-spacing: 0.5px;
    font-size: 35px;
    font-weight: 800;
  }
}
.text-shadow-title {
  text-shadow: 2px 0 0 $white, -2px 0 0 $white, 0 4px 0 rgba($white, 0.4),
    0 -2px 0 $white, 1px 1px $white, -1px -1px 0 $white, 1px -1px 0 $white,
    -1px 1px 0 $white;
}
.para-desc {
  max-width: 700px;
}
.mt-100 {
  margin-top: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}
//Shapes
.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
  & > svg {
    transform: scale(2);
    width: 100%;
    height: auto;
    transform-origin: top center;
  }
}

.mover {
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
    .heading {
      font-size: 50px;
      font-weight: 800;
    }
  }
}
@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
  .title-heading {
    .heading {
      font-size: 35px !important;
    }
    .para-desc {
      font-size: 18px;
    }
  }
  .section-title {
    .title {
      font-size: 24px;
      font-weight: 800;
    }
  }
  .mt-60 {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .shape {
    bottom: -1px;
  }
}
