@import "~bootstrap/scss/bootstrap";

.wrapper {
  display: flex;
  .content {
    width: 100%;
    max-width: 700px;
    margin: auto;
    background-color: #fff;
    position: relative;
  }
  h1 {
    color: #404040;
  }
  .list {
    width: 100%;
    padding-left: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;

    // if you want to format text to one column

    // @media (max-width: 360px) {
    //   -webkit-column-count: 1;
    //   -moz-column-count: 1;
    //   column-count: 1;
    // }
  }
  .icon {
    img {
      width: 24px;
    }
  }
  .alert-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      top: -15px;
    }
  }
}

.form {
  label {
    width: 100%;
    font-size: 1rem;
    border: 2px solid #f2f2fe;
    border-radius: 10px;
    color: #34334e;
    font-weight: 500;
    transition: border-color 0.4s ease;
    padding: 10px 15px;
    > div {
      height: 44px;
    }
    .item-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .item-checkmark {
      position: relative;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 3px solid #b4a6f2;
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0.1875rem 0.1875rem 0.625rem -0.125rem rgba(0, 0, 0, 0.09);
    }
    &.active {
      cursor: pointer;
      border-color: #745be6;
      box-shadow: 0.1875rem 0.1875rem 0.625rem -0.125rem rgba(0, 0, 0, 0.09);
      .item-checkmark {
        border: 6px solid #745be6;
      }
    }
    .item-message {
      background-color: #f1eefc;
      color: #6e56e5;
      margin-left: 10px;
      padding: 10px 15px;
      border-radius: 25px;
      text-transform: uppercase;
      font-size: 12px;
    }
    @media (max-width: 767px) {
      flex-direction: column !important;
      > div {
        margin-bottom: 8px;
      }
      .item-message {
        padding: 0 12px;
        height: 24px;
        display: flex;
        align-items: center;
      }
    }
  }
  .item-title {
    margin-left: 10px;
  }
  .item-period {
    color: #b8b8d9;
    font-weight: 400;
  }
  .btn-send {
    background-color: #745be6;
    border-color: #745be6;
  }
}

.form-group {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.feature-highlight {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
  font-weight: 700;
}
