.pricing-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 120px 0 120px;
}

.cursor-pointer {
  cursor: pointer;
}
