.subtitle-highlight {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
  font-weight: 700;
}

.pricing-rates {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #adb5bd;
}

.pricing-rates:hover {
  transform: scale(1.03) !important;
  transition: all 0.3s ease-in-out !important;
}

.pricing-rates .title {
  font-weight: 700;
  font-size: 22px;
  margin-left: 0px !important;
}

.pricing-rates .price {
  font-weight: 400;
}

.pricing-rates .feature .feature-list {
  margin: auto auto 5px;
  font-size: 16px;
}

.pricing-rates.business-rate {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pricing-rates.business-rate:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  z-index: 2;
  border-color: #5850ec;
}

.pricing-rates.business-rate:hover .title {
  color: #5850ec;
}

.pricing-rates.starter-plan {
  -webkit-transform: none;
  transform: scale(1);
  z-index: 2;
  border-color: #5850ec;
}

.pricing-rates.best-plan {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.pricing-rates.best-plan .lable {
  width: 200px;
  position: absolute;
  top: 15px;
  right: -65px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}

.pricing-rates.best-plan .lable h6 {
  font-size: 12px;
}

.pricing-rates.best-plan:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

@media (max-width: 767px) {
  .pricing-rates.starter-plan {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
