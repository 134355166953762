@import "~bootstrap/scss/bootstrap";

.settings-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .headerSpace.container {
    min-height: 90px !important;
  }
}

.company-item {
  display: flex;
  outline: none !important;
  box-shadow: none;
}

img.company-logo {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  margin: 4px 0 4px;
  background-size: 100% 100%;
}

.company-name {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-left: 0.5rem;
}

.btn.btn-primary.btn-new-company {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}

.btn.btn-secondary.btn-upgrade-cta {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}

.list-group-item:first-child {
  border: none;
}

.list-group-item + .list-group-item {
  border: none;
}

.list-group-item:last-child {
  border: none;
}

.list-group-item-light.list-group-item-action.active {
  color: #8c8c8d !important;
  background-color: #ececf6 !important;
  border-color: #ececf6 !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.upgrade-cta-btn {
  background-color: #ff9d3d;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.upgrade-cta-btn:hover {
  background-color: #fd8e1e;
}

.custom-domain-tips {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
}

.btn-icon {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  color: #0278ae !important;
  .btn-span {
    height: auto;
    margin-right: 10px;
    display: flex;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &:hover {
    text-decoration: none !important;
    opacity: 0.7;
    color: rgb(88, 87, 87);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btn-remove {
  display: inline-flex;
  padding-right: 0;
  color: rgb(88, 87, 87) !important;
  .btn-span {
    margin-right: 0;
    margin-left: 6px;
  }
}
