@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/custom.scss";

//Import Light mode
@import "./scss/style.scss";

//Import Dark RTL mode
// @import './scss/style-dark-rtl.scss';

//Import RTL mode
// @import './scss/style-rtl.scss';

//Import Dark Mode
// @import './scss/style-dark.scss';

/*Sidebar CSS*/
.headerSpace.container {
  min-height: 150px;
}

.row.mtop-minus {
  margin-top: -15%;
}
img.avatar.img-circle.img-thumbnail {
  border-radius: 50%;
  width: 150px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.col-sm-3.user_col {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}

.text-left h4 {
  font-size: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.text-left p {
  font-size: 14px;
  color: #909090;
  margin-bottom: 0px;
}

.text-left a {
  font-size: 13px;
  font-weight: 600;
}
ul.list-group.border-less {
  margin-top: 10px;
}
ul.list-group.border-less li {
  border-color: transparent;
  padding: 0;
}

ul.list-group.border-less li a {
  color: #b7b7b7;
  text-decoration: none;
  padding: 7px 5px;
  display: block;
  font-size: 14px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

li.list-group-item a:hover {
  background: #fbfcfb;
  border-radius: 5px;
  color: #000 !important;
  font-weight: 700;
}

.list-item-selected {
  background: #fbfcfb;
  border-radius: 5px;
  color: #000 !important;
  font-weight: 700;
}

/*Main Content*/
.border {
  border: 1px solid #e9e9e9 !important;
  padding: 10px 20px;
  border-radius: 5px;
}
.border h3 {
  font-size: 22px;
}

.form-group h5 {
  font-size: 17px;
}
.form-group {
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(128, 189, 255, 0.14);
  padding-bottom: 10px;
  padding-top: 10px;
}

.form-control {
  background-color: #f6f9fc;
  background-clip: padding-box;
  border: 1px solid #f6f9fc;
  font-size: 14px;
}

.form-control:focus {
  color: #495057;
  background-color: #f6f9fc;
  border-color: rgba(128, 189, 255, 0.14);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.02);
}
label {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
}

.border-less {
  border-color: transparent;
}
.form-group.text-right.border-less {
  padding-top: 35px;
}
.custom-control.custom-radio.custom-control-inline label {
  font-size: 14px;
  color: #9e9e9e;
  font-weight: normal;
}
h3.title {
  margin-left: -15px;
  font-size: 26px;
  padding-top: 20px;
}

.title {
  font-size: 26px;
  padding-top: 20px;
  margin-left: -15px;
  font-weight: 600;
}

ul.list-group.border-less li a i {
  padding-right: 4px;
}

.col-sm-3.user_col {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
  background: #fff;
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .col-sm-3.user_col,
  .col-sm-9 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 15px;
  }
  .col-sm-3.user_col {
    -ms-flex: 0 0 94%;
    flex: 0 0 94%;
    max-width: 94%;
    margin-right: 15px;
  }
  .row.mtop-minus {
    margin-top: -25%;
  }
}

.col-sm-9 .border {
  background: #fff;
  min-height: 501px;
}
.main {
  background-color: #f6f9fc;
}

button.btn.btn-lg.btn-primary {
  border-radius: 10px;
  font-size: 16px;
  padding: 6px 30px;
}

.row.mtop-minus .col-12 {
  padding-bottom: 10px;
}

.ProfileSection {
  max-width: 600px;
  margin: 0 auto;
}

.profileCta {
  background: #f6f9fc;
  padding: 0px 0px;
  padding-bottom: 45px;
  padding-top: 15px;
}

.col-6.text-right a i {
  color: #007bff;
  font-size: 18px;
}

a:hover {
  text-decoration: none;
}

.col-6.text-left span {
  color: #444;
  font-weight: 300;
  font-size: 15px;
}

.detailsText.text-left h3 {
  font-weight: 800;
  margin-bottom: 0;
  font-size: 32px;
}
.detailsText.text-left p {
  font-size: 15px;
}

.detailsText.text-center h3 {
  font-weight: 800;
  margin-bottom: 0;
  font-size: 32px;
}
.detailsText.text-center p {
  font-size: 15px;
}

.ProfileSection {
  padding-left: 15px;
  padding-right: 15px;
}

.video_titles {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
}
.vdo_imgs {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
  margin-right: 10px;
}
.vdo_imgs img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

@media (max-width: 1500px) {
  .vdo_imgs {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    margin-right: 10px;
  }
  .vdo_imgs img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}

.vdo_metas h4 {
  font-weight: 600;
  color: #030303;
}

.vdo_metas h5 {
  font-size: 13px;
  color: #9e9e9e;
  font-weight: 400;
  margin-bottom: 1px;
  margin-top: 1px;
}

.vdo_metas p {
  font-size: 10px;
  color: #9e9e9e;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) and (min-width: 240px) {
  .col-sm-3.user_col {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .row.mtop-minus {
    margin-top: -40%;
  }
  .title {
    font-size: 26px;
    padding-top: 20px;
    margin-left: 0px;
  }
  .form-group .row .col-7 {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .form-group .row .col-5 {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .row.nest-row .col-6 {
    padding-right: 5px;
  }

  .row.nest-row .col-6:nth-child(2) {
    padding-left: 5px;
    padding-right: 15px;
  }

  .form-group .col-11 {
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  .form-group .col-1 {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .row.mtop-minus {
    margin-top: -20%;
  }
  .row.nest-row .col-6 {
    padding-right: 5px;
  }

  .row.nest-row .col-6:nth-child(2) {
    padding-left: 5px;
    padding-right: 15px;
  }
  .col-sm-3.user_col {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background: #fff;
  }
  img.avatar.img-circle.img-thumbnail {
    border-radius: 50%;
    width: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .form-group .col-11 {
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  .form-group .col-1 {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }
}

.add-new-btn-black {
  color: #212529 !important;
}

.add-new-btn-black:hover {
  color: #212529 !important;
}
